import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#___gatsby');

const Modal = ({ children, isOpen, style }) => {
	return (
		<ReactModal isOpen={isOpen} style={style}>
			{children}
		</ReactModal>
	);
};

export default Modal;
