import React, { Component } from 'react';

import Icon from '-!svg-react-loader?name=CloseX!../../img/Lightbox_X.svg';

class CloseIcon extends Component {
	render() {
		return <Icon />;
	}
}

export default CloseIcon;
